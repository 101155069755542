<template>
  <div v-if="rollupLoading['error'] != null" class="rollup-table">
    <p>Error: {{ rollupLoading['error'] }}</p>
  </div>

  <div class="position-relative mb-6" v-else>
    <v-progress-circular v-if="rollupLoading['loading']" indeterminate :size="48" color="#79c61c" />
    
    <div :class="`tables-container ${rollupLoading['loading'] ? 'loading' : ''}`">
      <v-simple-table :class="`rollup-table-headers ${groupId == null ? 'overview-rollup' : 'individual-rollup'}`">
        <thead>
            <tr>
              <th data-header="region">Region</th>
              <th data-header="crop">Crops</th>
              <!-- start Group Detail headers -->
              <th data-header="med" class="group-col" v-if="groupId != null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Group Requests</p>
                  <p class="w-100 ma-0">This group's enrollment requests</p>
                </div>
              </th>
              <th data-header="med" class="group-col" v-if="groupId != null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Group Pending Requests</p>
                  <p class="w-100 ma-0">This group's unsubmitted acres</p>
                </div>
              </th>
              <th data-header="med" class="group-col" v-if="groupId != null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Group Enrollments</p>
                  <p class="w-100 ma-0">This group's matched requests</p>
                </div>
              </th>
              <th data-header="med" v-if="groupId != null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Allotted</p>
                  <p class="w-100 ma-0">Available program space</p>
                </div>
              </th>
              <th class="last" data-header="med" v-if="groupId != null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Remaining</p>
                  <p class="w-100 ma-0">AC to be filled</p>
                </div>
              </th>
              <!-- end Group Detail headers -->
              <!-- start Group Overview headers -->
              <th data-header="med" v-if="groupId == null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Allotted</p>
                  <p class="w-100 ma-0">Available program space</p>
                </div>
              </th>
              <th data-header="med" v-if="groupId == null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Requested</p>
                  <p class="w-100 ma-0">Enrollment requests</p>
                </div>
              </th>
              <th data-header="med" v-if="groupId == null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Pending Requests</p>
                  <p class="w-100 ma-0">Unsubmitted requests</p>
                </div>
              </th>
              <th data-header="med" v-if="groupId == null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Enrolled</p>
                  <p class="w-100 ma-0">AC Matched to programs</p>
                </div>
              </th>
              <th class="last" data-header="med" v-if="groupId == null">
                <div class="d-flex align-center flex-wrap">
                  <p class="w-100 ma-0">Remaining</p>
                  <p class="w-100 ma-0">AC to be filled</p>
                </div>
              </th>
              <!-- end Group Overview headers -->
            </tr>
          </thead>
      </v-simple-table>

      <v-data-table
        hide-default-header
        hide-default-footer
        disable-pagination
        :class="`rollup-table ${groupId == null ? 'overview-rollup' : 'individual-rollup'}`"
        :style="`--custom-table-height: ${tableHeight}px`"
        :headers="groupId == null ? OVERVIEW_ROLLUP_HEADERS : INDIVIDUAL_ROLLUP_HEADERS"
        :items="groupId == null ? overviewItems : groupItems"
        @current-items="(items) => filteredItems = items"
      >
        <template v-slot:header>
          <th data-header="region"></th>
          <th data-header="crop"></th>
          <!-- start Group Detail headers -->
          <th data-header="med" v-if="groupId != null"></th>
          <th data-header="med" v-if="groupId != null"></th>
          <th data-header="med" v-if="groupId != null"></th>
          <th data-header="med" v-if="groupId != null"></th>
          <th class="last" data-header="med" v-if="groupId != null"></th>
          <!-- end Group Detail headers -->
          <!-- start Group Overview headers -->
          <th data-header="med" v-if="groupId == null"></th>
          <th data-header="med" v-if="groupId == null"></th>
          <th data-header="med" v-if="groupId == null"></th>
          <th data-header="med" v-if="groupId == null"></th>
          <th class="last" data-header="med" v-if="groupId == null"></th>
          <!-- end Group Overview headers -->
        </template>

        <template v-slot:item="{ item }">
          <tr @click="applyRollupFilterset(item)">
            <td>{{ item['region']['value'] }}</td>
            <td>
              <span v-if="item['crops']['value'].length == 1 && item['crops']['value'][0] == 4 && item['crops']['harvest_type'] == 'Silage'">
                Corn (Silage)
              </span>
              <span v-else>{{ item['crops']['value'].map(c => CROP_DISPLAY_NAME[c]).join(', ') }}</span>
            </td>
            <!-- start Group Detail items -->
            <td class="group-col" v-if="groupId != null">{{ numberToLocale(item['groupRequests']) }}</td>
            <td :class="`${item['groupPendingRequests'] < 0 ? 'negative-color' : 'pending-color'} group-col`" v-if="groupId != null">
              {{ numberToLocale(item['groupPendingRequests']) }}
            </td>
            <td class="group-col" v-if="groupId != null">{{ numberToLocale(item['groupEnrollments']) }}</td>
            <td v-if="groupId != null">{{ numberToLocale(item['totalAllotted']) }}</td>
            <td :class="item['totalRemaining'] > 0 ? 'negative-color' : ''" v-if="groupId != null">{{ numberToLocale(item['totalRemaining']) }}</td>
            <!-- end Group Detail items -->
            <!-- start Group Overview items -->
            <td v-if="groupId == null">{{ numberToLocale(item['totalAllotted']) }}</td>
            <td v-if="groupId == null">{{ numberToLocale(item['totalRequested']) }}</td>
            <td :class="`${item['totalPendingRequests'] < 0 ? 'negative-color' : 'pending-color'}`" v-if="groupId == null">
              {{ numberToLocale(item['totalPendingRequests']) }}
            </td>
            <td v-if="groupId == null">{{ numberToLocale(item['totalEnrolled']) }}</td>
            <td :class="`${item['totalRemaining'] > 0 ? 'negative-color' : ''} last`" v-if="groupId == null">{{ numberToLocale(item['totalRemaining']) }}</td>
            <!-- end Group Overview items -->
          </tr>
        </template>
      </v-data-table>
  
      <v-simple-table :class="`rollup-table-summary ${groupId == null ? 'overview-rollup' : 'individual-rollup'}`">
        <a @click="toggleShowRollup">
          <img :style="`--flipped: ${showRollupTable ? '0deg' : '180deg'}`" :src="chevronUp">
        </a>
  
        <thead>
          <th data-header="region"></th>
          <th data-header="crop"></th>

          <!-- start Group Detail items -->
          <th v-if="groupId == null" data-header="med">{{ numberToLocale(overviewSummary['totalAllotted']) }}</th>
          <th v-if="groupId == null" data-header="med">{{ numberToLocale(overviewSummary['totalRequested']) }}</th>
          <th v-if="groupId == null" :class="`${overviewSummary['totalPendingRequests'] < 0 ? 'negative-color' : 'pending-color'}`" data-header="med">
            {{ numberToLocale(overviewSummary['totalPendingRequests']) }}
          </th>
          <th v-if="groupId == null" data-header="med">{{ numberToLocale(overviewSummary['totalEnrolled']) }}</th>
          <th :class="`${overviewSummary['totalRemaining'] ? 'negative-color' : ''} last`" v-if="groupId == null" data-header="med">{{ numberToLocale(overviewSummary['totalRemaining']) }}</th>
          <!-- end Group Detail items -->
          <!-- start Group Overview items -->
          <th v-if="groupId != null" class="group-col" data-header="med">{{ numberToLocale(individualSummary['groupRequests']) }}</th>
          <th v-if="groupId != null" :class="`${individualSummary['groupPendingRequests'] < 0 ? 'negative-color' : 'pending-color'} group-col`" data-header="med">
            {{ numberToLocale(individualSummary['groupPendingRequests']) }}
          </th>
          <th v-if="groupId != null" class="group-col" data-header="med">{{ numberToLocale(individualSummary['groupEnrollments']) }}</th>
          <th v-if="groupId != null" data-header="med">{{ numberToLocale(individualSummary['totalAllotted']) }}</th>
          <th :class="`${individualSummary['totalRemaining'] ? 'negative-color' : ''} last`" v-if="groupId != null" data-header="med">{{ numberToLocale(individualSummary['totalRemaining']) }}</th>
          <!-- end Group Overview items -->
        </thead>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import { mapState } from "vuex"
import { numberToLocale } from "@/utility"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import {
  ROLLUP_TABLE,
  ENROLLMENT_TABLE,
  REQUESTED,
  REQUESTED_PENDING,
  CONFIRMED
} from "@/constants/contractGroups"

const COMMON_HEADERS = [
  {
    text: "Region",
    value: "region",
    filter: v => v['selectedRegions'].length == 0 || v['selectedRegions'].some(r => v['value'].includes(r))
  },
  {
    text: "Crops",
    value: "crops",
    filter: v => {
      if (v['selectedCrops'].length == 0) return true
      for (const itemKey of v['selectedCrops']) {
        const [cropId, harvest_type] = itemKey.split('-')
        if (v['value'].includes(Number(cropId))) {
          if (Number(cropId) != 4) return true
          if (harvest_type == v['harvest_type']) return true
        }
      }
      return false
    }
  },
]


const OVERVIEW_ROLLUP_HEADERS = [
  ...COMMON_HEADERS,
  { text: "", value: "totalAllotted", sortable: false },
  { text: "", value: "totalRequested", sortable: false },
  { text: "", value: "totalEnrolled", sortable: false },
  { text: "", value: "totalRemaining", sortable: false },
]

const INDIVIDUAL_ROLLUP_HEADERS = [
  ...COMMON_HEADERS,
  { text: "", value: "groupRequests", sortable: false },
  { text: "", value: "groupEnrollments", sortable: false },
  { text: "", value: "totalAllotted", sortable: false },
  { text: "", value: "totalRemaining", sortable: false }
]

export default {
  name: "RollupTable",
  components: {},
  emits: ['updateSelected', 'updateRollupTableHeight'],
  props: {
    selectedRegions: { type: Array, required: true },
    selectedCrops: { type: Array, required: true },
  },
  data() {
    return {
      showRollupTable: true,
      filteredItems: [],
      OVERVIEW_ROLLUP_HEADERS,
      INDIVIDUAL_ROLLUP_HEADERS,
      CROP_DISPLAY_NAME,
      numberToLocale,
      chevronUp
    }
  },
  computed: {
    ...mapState({
      rollupData: state => state.EnrollmentGroups.rollupData,
      rollupLoading: state => state.EnrollmentGroups[ROLLUP_TABLE],
      enrollmentTableLoading: state => state.EnrollmentGroups[ENROLLMENT_TABLE],
      groupData: function(state) {
        if (this.groupId != null)
          return state.EnrollmentGroups.groupData[this.groupId]
        return state.EnrollmentGroups.groupData
      },
    }),
    groupId() {
      return this.$router.currentRoute.params['id']
    },
    overviewPendingRequests() {
      const allAcreageValues = Object.values(this.groupData).map(({ acreageValues }) => acreageValues)

      return this.rollupData.map(({ crop_ids, state_name, harvest_type }) => {
        let pendingRequests = 0

        for (const acreageValues of allAcreageValues) {
          for (const cropId of crop_ids) {
            if (cropId in acreageValues[REQUESTED_PENDING]) {
              let valuesObj = acreageValues[REQUESTED_PENDING][cropId]['Normal']

              if (cropId == 4) {
                valuesObj = acreageValues[REQUESTED_PENDING][cropId][harvest_type]
              }

              if (state_name in valuesObj) {
                pendingRequests += valuesObj[state_name]['acreage']
              }
            }
          }
        }

        return pendingRequests ? Math.round(pendingRequests) : 0
      })
    },
    overviewItems() {
      return this.rollupData.map(({
        crop_ids,
        state_name,
        harvest_type,
        total_available,
        total_requested,
        total_enrolled
      }, idx) => {
        let stateAbbrev = state_name
        const foundAbbrev = US_STATES.find(({ text }) => text == state_name)
        if (foundAbbrev != null) stateAbbrev = foundAbbrev['value']

        return {
          region: {
            value: stateAbbrev,
            fullName: state_name,
            selectedRegions: this.selectedRegions
          },
          crops: {
            harvest_type,
            value: crop_ids,
            selectedCrops: this.selectedCrops
          },
          totalAllotted: Math.round(total_available),
          totalRequested: Math.round(total_requested),
          totalEnrolled: Math.round(total_enrolled),
          totalPendingRequests: Math.round(this.overviewPendingRequests[idx]),
          totalRemaining: Math.round(total_available) - Math.round(total_enrolled) - Math.round(total_requested) > 0
            ? Math.round(total_available) - Math.round(total_enrolled) - Math.round(total_requested)
            : 0
        }
      })
    },
    groupItems() {
      if (this.groupData == null || this.groupData['fieldsupply'] == null) return []

      const { fieldsupply } = this.groupData

      const filteredRollupData = this.rollupData.filter(({ state_name, crop_ids }) =>
        fieldsupply.some(fss => state_name == fss['state_name'] && crop_ids.includes(fss['crop_id']))
      )

      return filteredRollupData.map(({ crop_ids, state_name, harvest_type, total_available }) => {
        const result = {}

        const totalRemaining = this.rollupData.reduce((acc, curr) => {
          if (crop_ids == curr['crop_ids'] && state_name == curr['state_name']) {
            const remaining = Math.round(curr['total_available']) - Math.round(curr['total_enrolled']) - Math.round(curr['total_requested'])
            if (remaining > 0) acc += remaining
          }
          return acc
        }, 0)

        for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING]) {
          result[s] = 0

          for (const cropId of crop_ids) {
            if (cropId in this.groupData['acreageValues'][s]) {
              if (state_name in this.groupData['acreageValues'][s][cropId][harvest_type]) {
                result[s] += this.groupData['acreageValues'][s][cropId][harvest_type][state_name]['acreage']
              }
            }
          }
        }

        let stateAbbrev = state_name
        const foundAbbrev = US_STATES.find(({ text }) => text == state_name)
        if (foundAbbrev != null) stateAbbrev = foundAbbrev['value']

        return {
          region: {
            value: stateAbbrev,
            fullName: state_name,
            selectedRegions: this.selectedRegions
          },
          crops: {
            harvest_type,
            value: crop_ids,
            selectedCrops: this.selectedCrops
          },
          groupRequests: Math.round(result[REQUESTED]),
          groupEnrollments: Math.round(result[CONFIRMED]),
          groupPendingRequests: Math.round(result[REQUESTED_PENDING]),
          totalAllotted: Math.round(total_available),
          totalRemaining
        }
      })
    },
    overviewSummary() {
      const props = ['totalAllotted', 'totalRequested', 'totalEnrolled', 'totalRemaining', 'totalPendingRequests']
      const {
        totalAllotted,
        totalRequested,
        totalEnrolled,
        totalRemaining,
        totalPendingRequests
      } = this.filteredItems.reduce((accum, cur) => {
        for (const prop of props) accum[prop] += parseFloat(cur[prop])
        return accum
      }, {
        totalAllotted: 0,
        totalRequested: 0,
        totalEnrolled: 0,
        totalRemaining: 0,
        totalPendingRequests: 0
      })

      return {
        region: null,
        crop: null,
        totalAllotted: Math.round(totalAllotted),
        totalRequested: Math.round(totalRequested),
        totalEnrolled: Math.round(totalEnrolled),
        totalRemaining: Math.round(totalRemaining),
        totalPendingRequests: Math.round(totalPendingRequests)
      }
    },
    individualSummary() {
      const props = [
        'groupRequests',
        'groupPendingRequests',
        'groupEnrollments',
        'totalAllotted',
        'totalRemaining'
      ]
      const {
        groupRequests,
        groupEnrollments,
        groupPendingRequests,
        totalAllotted,
        totalRemaining
      } = this.filteredItems.reduce((accum, cur) => {
        for (const prop of props) accum[prop] += parseFloat(cur[prop])
        return accum
      }, {
        groupRequests: 0,
        groupEnrollments: 0,
        groupPendingRequests: 0,
        totalAllotted: 0,
        totalRemaining: 0
      })

      return {
        region: null,
        crop: null,
        groupRequests: Math.round(groupRequests),
        groupPendingRequests: Math.round(groupPendingRequests),
        groupEnrollments: Math.round(groupEnrollments),
        totalAllotted: Math.round(totalAllotted),
        totalRemaining: Math.round(totalRemaining)
      }
    },
    tableHeight() {
      if (!this.showRollupTable) return 0
      return this.filteredItems.length >= 5
        ? 202
        : this.filteredItems.length * 44
    },
  },
  methods: {
    applyRollupFilterset({ crops, region }) {
      this.$emit('updateSelected', {
        crops: crops['value'].map(c => `${c}-${crops['harvest_type']}`),
        region: [region['value']]
      })
    },
    toggleShowRollup() {
      this.showRollupTable = !this.showRollupTable
    },
  },
  watch: {
    tableHeight(curr) {
      this.$emit('updateRollupTableHeight', curr)
    },
  }
}
</script>

<style scoped>
.tables-container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: scroll;
  overflow-y: hidden;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  background: #F9FAFB;
}
.rollup-table {
  border-radius: 0;
  max-width: unset;
  --custom-table-height: 179px;
}
.rollup-table-summary :deep(table) {
  border-top: 1px solid #E5E7EB;
}
.rollup-table :deep(.v-data-table__wrapper) {
  overflow: scroll !important;
}
.rollup-table-summary .v-data-table__wrapper {
  overflow: hidden !important;
}
.rollup-table :deep(table),
.rollup-table-summary :deep(table),
.rollup-table-headers :deep(table) {
  table-layout: fixed;
}
.rollup-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: var(--custom-table-height, 179px);
}
.rollup-table-headers :deep(thead th) {
  height: 58px !important;
}
.rollup-table-headers :deep(thead th > div > p:first-of-type) {
  color: #374151;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
.rollup-table-headers :deep(thead th > div > p:last-of-type) {
  color: #374151;
  font-size: 10px;
  font-style: italic;
  line-height: 14px;
  font-weight: 400;
  white-space: nowrap;
}
:deep(th[data-header="region"]) {
  width: 110px;
}
:deep(th[data-header="crop"]) {
  width: 175px;
}
:deep(th[data-header="med"]) {
  width: 200px;
}
:deep(.last) {
  padding-right: 24px !important;
}
:deep(td), :deep(th) {
  background-color: #F9FAFB;
}
:deep(tbody td) {
  height: 44px !important;
}
:deep(tr td:nth-of-type(2)) {
  white-space: nowrap;
}
:deep(tr:hover td) {
  background-color: #eee !important;
}
:deep(th[data-header="med"]) p,
:deep(th[data-header="med"]),
:deep(tr td:nth-of-type(n+3)) {
  text-align: right;
}
.rollup-table-summary thead th {
  color: #20292F;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  padding: 0 16px;
  height: 64px;
}
.rollup-table :deep(tbody tr) {
  cursor: pointer;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a) {
  display: flex;
  width: 96px;
  height: 64px;
  position: absolute;
  left: 1px;
  bottom: 1px;
  cursor: pointer;
  justify-content: flex-end;
}
.rollup-table-summary :deep(.v-data-table__wrapper > table > a img) {
  transform: rotate(var(--flipped, 0deg));
  transition: transform 0.25s ease-in-out;
  width: 36px;
  height: auto;
  margin-right: 36px;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 32px);
  top: calc(50% - 32px);
  z-index: 1;
}
.loading {
  opacity: 0.50;
  pointer-events: none;
}
.progress-min-width {
  width: 80px;
}
.pending-color {
  color: #0E7490 !important;
}
.negative-color {
  color: #EF4444 !important;
}
:deep(.group-col) {
  background-color: #F3F4F6;
}
</style>