<template>
  <div :class="`${view == CSM_VIEW ? 'superuser-view' : ''} pb-6 enrollment-container`">
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    <div>
      <div class="d-flex justify-space-between align-center mb-4">
        <div class="d-flex align-center">
          <h1 class="mb-0 font-weight-bold">Enrollment Groups</h1>
        </div>

        <div>
          <v-btn class="mr-3" :ripple="false" outlined height="44" href="https://www.loom.com/share/0ab36823ce6c4445b1fe35caa5bc3b53?sid=4a5d6533-732a-46e5-99f8-ecca64d33a2a" target='_blank'>
            <v-icon>mdi-play-outline</v-icon>
            3 Min Training
          </v-btn>

          <v-menu offset-y v-if="isSuperuser">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-3" :ripple="false" outlined height="44" v-bind="attrs" v-on="on">
                <v-icon>mdi-chevron-down</v-icon>
                <span class="ml-1">{{ view == CSM_VIEW ? 'CSM View' : 'CP View' }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group @change="setView" mandatory color="primary">
                <v-list-item>
                  <v-list-item-title>CP View</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>CSM View</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
  
      <RollupTable
        :selectedRegions="selectedRegions"
        :selectedCrops="selectedCrops"
        @updateSelected="handleUpdateSelected"
        @updateRollupTableHeight="updateRollupTableHeight"
      />

      <v-card outlined class="px-6 py-3 position-relative">
        <v-progress-circular v-if="enrollmentTableLoading['loading']" indeterminate :size="48" color="#79c61c" />

        <div v-if="enrollmentTableLoading['error'] != null">
          <p>Error: {{ enrollmentTableLoading['error'] }}</p>
        </div>

        <v-data-table
          v-else
          show-select
          hide-default-footer
          disable-pagination
          item-key="cropIdKey"
          :class="`enrollment-group-table ${enrollmentTableLoading['loading'] ? 'loading' : ''} ${view == CSM_VIEW ? 'csm-view' : ''}`"
          :style="`--table-height-modifier: ${allOtherHeights}px`"
          :search="search"
          :custom-filter="handleDataTableFiltering"
          :headers="view == CP_VIEW ? CP_HEADERS : CSM_HEADERS"
          :items="items"
          v-model="selectedItems"
          @current-items="(items) => filteredItems = items"
        >
          <template v-slot:top="props">
            <div
              v-bind="props"
              class="d-flex align-center justify-space-between mb-4"
            >
              <div class="d-flex align-center flex-wrap">
                <v-card-title class="pa-0 ma-0 w-100 order-1">
                  {{ 
                    enrollmentTableLoading['loading']
                      ? 'Loading group data...'
                      : `${allUniqueRenderedGroupIds.length} Enrollment Group${allUniqueRenderedGroupIds.length == 1 ? '' : 's'}`
                  }}
                </v-card-title>
                <v-card-subtitle class="pa-0 ma-0 mb-2 w-100 order-0">Enrollment Progress</v-card-subtitle>
              </div>

              <div class="d-flex justify-end">
                <div id="region-selector">
                  <v-select
                    solo
                    multiple
                    clearable
                    hide-details
                    attach="#region-selector"
                    placeholder="All Regions"
                    :items="allUniqueRegions"
                    v-model="selectedRegions"
                    :class="`mr-2 ${selectedRegions.length == 0 ? 'all-selected' : ''}`"
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index == 0">
                        <p class="ma-0 text-subtitle-2 font-weight-regular">
                          Regions <span>{{ selectedRegions.length }}</span>
                        </p>
                      </div>
                    </template>
  
                    <template v-slot:item="{ on, attrs, item }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active" />
                          </v-list-item-action>
  
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ regionAbbrevToFull(item) }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>

                <div id="crop-selector">
                  <v-select
                    solo
                    multiple
                    clearable
                    hide-details
                    item-value="itemKey"
                    v-model="selectedCrops"
                    attach="#crop-selector"
                    placeholder="All Crops"
                    :items="allUniqueCrops"
                    :class="`mr-2 ${selectedCrops.length == 0 ? 'all-selected' : ''}`"
                    :menu-props="{ bottom: true, offsetY: true }"
                  >
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index == 0">
                        <p class="ma-0 text-subtitle-2 font-weight-regular">
                          Crops <span>{{ selectedCrops.length }}</span>
                        </p>
                      </div>
                    </template>
  
                    <template v-slot:item="{ on, attrs, item }">
                      <v-list-item v-on="on" v-bind="attrs">
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active" />
                          </v-list-item-action>
  
                          <v-list-item-content>
                            <v-list-item-title v-if="item['id'] == 4 && item['harvest_type'] == 'Silage'">
                              Corn (Silage)
                            </v-list-item-title>
                            <v-list-item-title v-else>
                              {{ CROP_DISPLAY_NAME[item['id']] }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </v-list-item>
                    </template>
                  </v-select>
                </div>

                <v-text-field
                  :ripple="false"
                  class="mr-3 search-input"
                  outlined
                  v-model="search"
                  hide-details
                  placeholder="Search"
                >
                  <template v-slot:prepend-inner>
                    <v-icon>mdi-magnify</v-icon>
                  </template>
                  <span class="ml-1">Search</span>
                </v-text-field>
              </div>
            </div>
          </template>

          <template v-slot:header.requested>
            <span class="d-flex w-100 justify-center">Requested AC</span>
          </template>

          <template v-slot:header.requested_pending>
            <span class="d-flex w-100 justify-center">Pending Request</span>
          </template>

          <template v-slot:header.confirmed>
            <span class="d-flex w-100 justify-center">Confirmed AC</span>
          </template>

          <template v-slot:item="{ index, item, select }">
            <tr
              :data-is-first="filteredItems.findIndex(({ groupId }) => groupId == item['groupId']) == index ? 1 : 0"
              @click="handleClick(item['groupId'])"
            >
              <td><v-simple-checkbox :value="selectedItems.includes(item)" @input="select" /></td>
              <td><span>{{ item['name'] }}</span></td>
              <td>
                <div class="d-flex position-relative">
                  <span v-if="item['regions'] != null">
                    {{
                      item['regions']['value'].map(stateName => {
                        const found = US_STATES.find(({ text }) => stateName == text);
                        if (found != null) return found['value'];
                        return stateName;
                      }).join(', ')
                    }}
                  </span>
                </div>
              </td>
              <td>
                <div class="d-flex position-relative">
                  <span v-if="item['crop']['id'].length == 1 && item['crop']['id'] == 4 && item['crop']['harvest_type'] == 'Silage'">
                    Corn (Silage)
                  </span>
                  <span v-else>{{ item['crop']['id'].map(i => CROP_DISPLAY_NAME[i]).join(', ') }}</span>
                </div>
              </td>
              <td class="text-right">
                <span v-if="item[CONFIRMED] != null && item[CONFIRMED] != 0">
                  {{ item[CONFIRMED] != null && item[CONFIRMED] != 0 ? item[CONFIRMED].toLocaleString() : null }}
                </span>
              </td>

              <!-- CP View only -->
              <td :class="`${item[REQUESTED_PENDING] < 0 ? 'negative-color' : 'pending-color'} text-right`" v-if="view == CP_VIEW">
                {{ item[REQUESTED_PENDING] != null && item[REQUESTED_PENDING] != 0 ? item[REQUESTED_PENDING].toLocaleString() : null }}
              </td>
              <td class="text-right" v-if="view == CP_VIEW">
                <span v-if="item[REQUESTED] != null && item[REQUESTED] != 0">
                  {{ item[REQUESTED] != null && item[REQUESTED] != 0 ? item[REQUESTED].toLocaleString() : null }}
                </span>
              </td>
              <td v-if="view == CP_VIEW">
                <div class="py-2">
                  <v-btn
                    class="rounded-lg"
                    :disabled="item[REQUESTED_PENDING] == 0"
                    outlined
                    @click.stop.prevent="submitSupplyChanges({ groupId: item['groupId'], groupCrops: item['crop']['id'], crop_specific_snapshot: true })"
                  >
                    Send for review
                  </v-btn>
                </div>
              </td>
              <td v-if="view == CP_VIEW">
                <div>
                  <div class="d-flex flex-wrap" v-if="item['statusUpdate'] != null">
                    <p class="w-100 mb-0">{{ item['statusUpdate'] }}</p>
                    <p class="w-100 mb-0 submitted-subline">Submitted</p>
                  </div>
                  <div class="d-flex" v-else>
                    <p class="w-100 mb-0 submitted-subline">Unsubmitted</p>
                  </div>
                </div>
              </td>
              <!--  -->

              <!-- CSM View only -->
              <td v-if="view == CSM_VIEW">
                <div class="d-flex position-relative">
                  <v-chip v-if="item[REQUESTED] != null && item[REQUESTED] != 0" :class="`${item[REQUESTED] < 0 ? 'red' : 'green'}`">
                    {{ item[REQUESTED] < 0 ? '' : '+' }} {{ item[REQUESTED].toLocaleString() }}
                  </v-chip>
                </div>
              </td>
              <td @click.stop.prevent="() => {}" v-if="view == CSM_VIEW">
                <div class="py-2">
                  <v-btn class="rounded-lg" :disabled="item[REQUESTED] == 0 || item['evidencing_link'] == null || item['evidencing_link'].length == 0" outlined>
                    Confirm
                  </v-btn>
                </div>
              </td>
              <td @click.stop.prevent="() => {}" v-if="view == CSM_VIEW">
                <v-text-field
                  flat
                  solo
                  hide-details
                  variant="outlined"
                  label="Enter Evidencing link"
                  :value="item['evidencing_link']"
                  @change="(v) => saveEvidencingLink(v, item)"
                />
              </td>
              <!--  -->
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import Permissions from "@/components/permissions/Permissions"
import RollupTable from "@/components/contractGroups/RollupTable.vue"
import { regionAbbrevToFull } from "@/utility"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import { EnrollmentGroups } from "@/store/modules"
import { mapState, mapActions, mapGetters, mapMutations } from "vuex"
import { REQUESTED, CONFIRMED, REQUESTED_PENDING, ENROLLMENT_TABLE, CP_VIEW, CSM_VIEW } from "@/constants/contractGroups"

const COMMON_HEADERS = [
  { text: "Enrollment Group Name", value: "name", sortable: false },
  {
    text: "Regions",
    value: "regions",
    sortable: false,
    filter: v => {
      if (v['selectedRegions'].length == 0) return true
      if (v['selectedRegions'].some(r => v['value'].includes(regionAbbrevToFull(r)))) return true
      return false
    }
  },
  {
    text: "Crops",
    value: "crop",
    sortable: false,
    filter: v => {
      if (v['selectedCrops'].length == 0) return true
      for (const itemKey of v['selectedCrops']) {
        const [cropId, harvest_type] = itemKey.split('-')
        if (v['id'].includes(Number(cropId))) {
          if (Number(cropId) != 4) return true
          if (harvest_type == v['harvest_type']) return true
        }
      }
      return false
    }
  },
]

const CP_HEADERS = [
  ...COMMON_HEADERS,
  { text: "Confirmed", value: CONFIRMED, sortable: false },
  { text: "Pending Request", value: REQUESTED_PENDING, sortable: false },
  { text: "Requested AC", value: REQUESTED, sortable: false },
  { text: "Submit", value: "submit, sortable: false" },
  { text: "Status update", value: "statusUpdate", sortable: false }
]

const CSM_HEADERS = [
  ...COMMON_HEADERS,
  { text: "Confirmed", value: CONFIRMED, sortable: false },
  { text: "Submitted AC Pending", value: "confirmedPending", sortable: false },
  { text: "Confirm Fields", value: "confirmAction", sortable: false },
  { text: "", value: "ellipsisMenu", sortable: false }
]

export default {
  name: "ContractGroups",
  components: { Permissions, RollupTable },
  data() {
    return {
      search: '',
      rollupTableHeight: 222,
      filteredItems: [],
      selectedItems: [],
      selectedCrops: [],
      selectedRegions: [],
      CP_HEADERS,
      CSM_HEADERS,
      CROP_DISPLAY_NAME,
      CONFIRMED,
      REQUESTED,
      REQUESTED_PENDING,
      US_STATES,
      chevronUp,
      CP_VIEW,
      CSM_VIEW
    }
  },
  computed: {
    ...mapState({
      isSuperuser: state => state.User.user != null && state.User.user['is_superuser'],
      org: state => state.Organization.organization,
      year: state => state.Organization.year,
      enrollmentGroupOrgId: state => state.EnrollmentGroups.org,
      enrollmentTableLoading: state => state.EnrollmentGroups[ENROLLMENT_TABLE],
      view: state => state.EnrollmentGroups.view,
      allGroupData: state => state.EnrollmentGroups.groupData
    }),
    ...mapGetters({
      allUniqueCrops: EnrollmentGroups.Getters.allUniqueCrops,
      allUniqueRegions: EnrollmentGroups.Getters.allUniqueRegions
    }),
    allUniqueRenderedGroupIds() {
      return this.filteredItems.reduce((accum, { groupId }) => {
        if (!accum.includes(groupId)) accum.push(groupId)
        return accum
      }, [])
    },
    allOtherHeights() {
      // navbar + page container margin top + h1 + h1 margin + table + table result + table margin
      const external = 60 + 20 + 48 + 16 + this.rollupTableHeight + 65 + 24

      // table borders, table y padding + "top" slot + "top" slot margin
      const internal = 2 + 24 + 60 + 12

      const rollupHead = 58

      // 24px of artificial padding at bottom
      const extra = 30

      return external + internal + rollupHead + extra
    },
    items() {
      const rows = []

      for (const id in this.allGroupData) {
        const { name, timestamps, cropRegionMap, acreageValues, evidencing_links } = this.allGroupData[id]
        
        const groupRows = []
      
        for (const cropId in cropRegionMap) {
          const result = {
            name,
            groupId: id,
            cropIdKey: `${id}-${cropId}`,
            evidencing_link: evidencing_links[cropId],
            statusUpdate: timestamps.hasOwnProperty(cropId)
              ? new Date(timestamps[cropId]).toLocaleString([], {
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                  hour: 'numeric',
                  minute:'2-digit'
                })
              : null,
            crop: {
              id: [Number(cropId)],
              selectedCrops: this.selectedCrops,
              harvest_type: cropRegionMap[cropId]['harvest_type'],
            },
            regions: {
              value: cropRegionMap[cropId]['regions'].sort((a, b) => a.localeCompare(b)),
              selectedRegions: this.selectedRegions
            }
          }

          for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING]) {
            if (!result.hasOwnProperty(s)) result[s] = 0

            for (const region of result['regions']['value']) {
              const found = US_STATES.find(({ text }) => region == text);
              if (found != null && this.selectedRegions.length != 0 && !this.selectedRegions.includes(found['value']))
                continue

              if (acreageValues[s].hasOwnProperty(cropId)) {
                if (this.selectedCrops.length != 0) {
                  const found = this.selectedCrops.find(key => {
                    const [crop, harvestType] = key.split('-')
                    return Number(cropId) == Number(crop) && harvestType == cropRegionMap[cropId]['harvest_type']
                  })

                  if (found == null) continue
                }
                
                if (region in acreageValues[s][cropId][cropRegionMap[cropId]['harvest_type']]) {
                  result[s] += acreageValues[s][cropId][cropRegionMap[cropId]['harvest_type']][region]['acreage']
                }
              }
            }
          }

          groupRows.push(result)
        }
  
        groupRows.sort((a, b) => 
          CROP_DISPLAY_NAME[a['crop']['id'][0]].localeCompare(CROP_DISPLAY_NAME[b['crop']['id'][0]])
        )
        
        // doing this after the fact as it's a shim and the above logic should be correct long-term
        // take all winter wheat rows and combine them with wheat rows, if they both exist
        const wheatRow = groupRows.find(({ crop }) => crop['id'].includes(7))
        const winterWheatRow = groupRows.find(({ crop }) => crop['id'].includes(30))

        if (winterWheatRow != null && wheatRow != null) {
          winterWheatRow['remove'] = true

          wheatRow['crop']['id'] = [7, 30]
          wheatRow['evidencing_link'] = evidencing_links['7-30']

          for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING]) {
            wheatRow[s] += winterWheatRow[s]
          }

          for (const r of winterWheatRow['regions']['value']) {
            if (!wheatRow['regions']['value'].includes(r)) {
              wheatRow['regions']['value'].push(r)
            }
          }
          wheatRow['regions']['value'].sort((a, b) => a.localeCompare(b))
        }

        const filteredGroupRows = groupRows.filter(({ remove }) => {
          if (remove) return false
          return true
        })
    
        rows.push(...filteredGroupRows)
      }

      return rows.sort((a, b) => a['name'].localeCompare(b['name']))
    }
  },
  methods: {
    saveEvidencingLink(newLink, { crop, groupId }) {
      const { evidencing_links } = this.allGroupData[groupId]
      const updated_link_obj = { ...evidencing_links }

      const cropKey = crop['id'].join('-')
      updated_link_obj[cropKey] = newLink

      this.submitEvidencingLink({ group_id: groupId, updated_link_obj })
    },
    regionAbbrevToFull,
    ...mapActions({
      fetchRollup: EnrollmentGroups.Actions.fetchRollup,
      submitSupplyChanges: EnrollmentGroups.Actions.submitSupplyChanges,
      submitEvidencingLink: EnrollmentGroups.Actions.submitEvidencingLink
    }),
    ...mapMutations({
      setView: EnrollmentGroups.Mutations.setView
    }),
    handleUpdateSelected({ crops, region }) {
      if (
        crops.every(c => this.selectedCrops.includes(c))
        && crops.length == this.selectedCrops.length
        && region.every(r => this.selectedRegions.includes(r))
        && region.length == this.selectedRegions.length
      ) {
        this.selectedCrops = []
        this.selectedRegions = []
      }
      else {
        this.selectedCrops = crops
        this.selectedRegions = region
      }
    },
    updateRollupTableHeight(height) {
      this.rollupTableHeight = height
    },
    handleDataTableFiltering(value, search, item) {
      if (search == null || search.length == 0) return true
      
      const groupName = item['name'].toLowerCase()
      if (groupName.includes(search)) return true
      return false
    },
    handleClick(groupId) {
      this.$router.push(`/enrollment-groups/${groupId}`)
    }
  },
  mounted() {
    const badOrg = this.org != null && this.enrollmentGroupOrgId != null && this.org.id != this.enrollmentGroupOrgId
    if (Object.keys(this.allGroupData).length == 0 || badOrg) {
      this.fetchRollup({ fetchOverview: true })
    }
  },
  watch: {
    org() {
      this.fetchRollup({ fetchOverview: true })
    },
    year() {
      this.fetchRollup({ fetchOverview: true })
    }
  }
}
</script>

<style scoped>
p {
  color: #000000;
}
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  transition: color 0.25s;
}
.superuser-view h1 {
  color: #ffffff;
}
.v-data-table :deep(tr) {
  cursor: pointer;
}
.enrollment-group-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: calc(100vh - var(--table-height-modifier, 0));
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 2;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
.v-chip.v-size--default {
  height: 20px !important;
}
.enrollment-group-table :deep(table) {
  table-layout: fixed;
  border-collapse: separate;
}
.enrollment-group-table :deep(th:nth-of-type(1)) {
  width: 52px !important;
}
.enrollment-group-table :deep(th:nth-of-type(2)) {
  width: 200px;
}
.enrollment-group-table :deep(th:nth-of-type(3)) {
  width: 98px;
}
.enrollment-group-table :deep(th:nth-of-type(4)) {
  width: 168px;
}
.enrollment-group-table :deep(th:nth-of-type(5)),
.enrollment-group-table :deep(th:nth-of-type(7)) {
  width: 130px;
}
.enrollment-group-table :deep(th:nth-of-type(6)) {
  width: 158px;
}
.enrollment-group-table :deep(th:nth-of-type(8)) {
  width: 152px;
}
.enrollment-group-table.csm-view :deep(th:nth-of-type(8)) {
  width: 250px;
}
.enrollment-group-table :deep(th:nth-of-type(9)) {
  width: 200px;
}
.enrollment-group-table :deep(.v-data-table__selected td),
.enrollment-group-table :deep(tr:hover td) {
  background: #f5f5f5 !important;
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(td:nth-of-type(2)),
.enrollment-group-table :deep(td:nth-of-type(9)),
.enrollment-group-table :deep(th:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(2)) {
  position: sticky;
  z-index: 1;
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(td:nth-of-type(2)),
.enrollment-group-table :deep(th:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(2)) {
  background: #FFFFFF;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(1)) {
  left: 0;
}
.enrollment-group-table :deep(td:nth-of-type(2)),
.enrollment-group-table :deep(th:nth-of-type(2)) {
  left: 52px;
}
.enrollment-group-table :deep(.v-btn) {
  color: #487F06;
  letter-spacing: normal;
  text-transform: none;
  border-color: #c7c9ce;
}
.enrollment-group-table :deep(tr[data-is-first="1"]:has(+tr[data-is-first="0"]) td),
.enrollment-group-table :deep(tr[data-is-first="0"]:has(+tr[data-is-first="0"]) td) {
  border-bottom: none !important;
}
.enrollment-group-table :deep(tr[data-is-first="0"] td:nth-of-type(2) span) {
  display: none;
}
.enrollment-group-table :deep(tr td:nth-of-type(9) p) {
  line-height: 1.5 !important;
}
/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  border-radius: 8px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"]) {
  background: #6B7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected) :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-chip.green {
  background: #F1FDDF !important;
}
.v-chip.green :deep(span) {
  color: #61B100;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
}
.search-input :deep(.v-input__slot) {
  min-height: unset !important;
  height: 40px;
}
.search-input :deep(.v-input__prepend-inner) {
  margin: 2px 0 0;
  align-self: center;
}
.all-selected :deep(::placeholder) {
  color: #374151 !important;
  opacity: 1;
}
::v-deep(.v-input__control input) {
  cursor: pointer;
}
.v-progress-circular {
  position: absolute;
  z-index: 1;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  background: #FFFFFF;
}
.v-btn.no-click-events {
  pointer-events: none;
}
.v-btn p {
  margin: 0;
  line-height: 1.125;
  text-align: left;
  font-weight: normal;
}
.v-btn p:last-of-type {
  font-size: 12px;
}
.submitted-subline {
  color: #4B5563;
  font-size: 14px;
  line-height: 20px;
}
#crop-selector, #region-selector {
  position: relative;
}
.enrollment-container {
  height: calc(100vh - 80px);
}
.pending-color {
  color: #0E7490;
}
.negative-color {
  color: #EF4444 !important;
}
</style>
