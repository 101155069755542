<template>
  <div>
    <div v-if="fieldSupplySummaryDataFiltered.length > 0">
      <v-toolbar>
        <v-text-field
          v-model="includedSearch"
          label="Search By Attribute"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search by Field ID"
          v-model="fieldIDString"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          variant="solo"
          hide-details
          clearable
          single-line
          @click:clear="fieldIDSearch('')"
          @click:prepend-inner="fieldIDSearch(fieldIDString)"
          @keydown.enter="fieldIDSearch(fieldIDString)"
        ></v-text-field>
        <v-btn
          class="ma-2 py-6"
          color="primary"
          v-if="selectedFSSForAllocationFiltered.length > 0"
          @click="handleFSSDetailsModal(selectedFSSForAllocationFiltered)"
        >
          Allocate for {{ selectedFSSForAllocationFiltered.length }} Field Crops
          <br />( {{ selectedAcreage }} acres)</v-btn
        >
        <v-btn
          v-if="filterUsed"
          class="ma-2 py-6"
          color="dark red lighten-1"
          @click="clearAllFilters"
          >Clear Filters <br />({{ filteredSummaryText }})</v-btn
        >
        <div v-if="showLoading" class="mt-3 ml-6 d-flex justify-content-center">
          <div class="text-bold mb-0">Fetching Data</div>
          <div class="spinner-border ml-4" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </v-toolbar>
      <v-layout column style="height: 75vh">
        <v-flex style="overflow: auto">
          <v-data-table
            v-model="selectedFSSForAllocation"
            :headers="enrollmentHeaders"
            :items="fieldSupplySummaryDataFiltered"
            :search="includedSearch"
            :items-per-page="100"
            height="55vh"
            fixed-header
            fixed-footer
            item-key="id"
            show-select
          >
            <template v-slot:header="{ header }">
              <VDataTablesMultiSelectHeader
                :items="fieldSupplySummaryData"
                :headers="enrollmentHeaders"
                :filters="filters"
                showIcon="true"
                @filtered-data="filteredData"
              />
            </template>

            <template slot="item.fieldcrop.field_name" slot-scope="props">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <span
                      class="hover"
                      @click="handeShowFieldDetailsCompletionModal(props.item)"
                      style="max-width: 100px"
                    >
                      {{ props.item.fieldcrop.field_name }}
                      <v-icon color="blue"> mdi-list-status </v-icon>
                    </span>
                  </div>
                </template>
                <span>Click to see Field Data Completion</span>
              </v-tooltip>
            </template>

            <template slot="item.ea_orders_name" slot-scope="props">
              <v-btn
                v-if="
                  props.item.num_matches > 0 &&
                  ['available', 'requested', 'requested_pending'].includes(
                    props.item.status
                  ) &&
                  props.item.ea_orders_name == null
                "
                color="dark green lighten-1"
                small
                @click="handleFSSDetailsModal(props.item)"
                >Select Program</v-btn
              >
              <span v-if="props.item.ea_orders"
                ><v-chip color="primary" max-width="40px">{{
                  props.item.ea_orders.name
                }}</v-chip>
                <i
                  v-if="
                    ['locked', 'matched', 'confirmed'].includes(
                      props.item.status
                    ) && props.item.ea_orders_name != null
                  "
                  class="pt-2 fa fa-times-circle float-right"
                  @click="handleOpenFSSRemoveModal(props.item)"
                ></i>
              </span>
            </template>
            <!--<template slot="item.ea_orders" slot-scope="props">
          <span v-if="props.item.ea_orders"
            >{{ props.item.ea_orders.name }}
            <i
              v-if="props.item.status == 'locked'"
              class="pt-2 fa fa-times-circle float-right"
              @click="handleOpenFSSRemoveModal(props.item)"
            ></i>
          </span>
        </template>-->
            <template slot="item.practices" slot-scope="props">
              <span v-if="props.item.practices" v-html="props.item.practices">
              </span>
            </template>
            <template
              v-slot:item.data-table-select="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                v-if="
                  [
                    'available',
                    'requested',
                    'requested_pending',
                    //'matched',
                    //'confirmed',
                  ].includes(item.status) &&
                  item.num_matches > 0 &&
                  item.ea_orders_name == null
                "
                :readonly="
                  ['matched', 'confirmed', 'locked'].includes(item.status) ||
                  item.ea_orders_name != null
                "
                :disabled="
                  ['matched', 'confirmed', 'locked'].includes(item.status) ||
                  item.ea_orders_name != null
                "
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </div>
    <div v-else>
      <div v-if="showLoading" class="mt-3 d-flex justify-content-center">
        <div class="text-bold mb-0">Fetching Data</div>
        <div class="spinner-border ml-4" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <h4 class="ma-2">
          No Data Currently Available For Year In Focus: {{ yearInFocus }}
        </h4>
      </div>
    </div>

    <ReviewFSSDetails
      v-if="showFSSDetailsModal"
      :items="items"
      :fssPrograms="fssPrograms"
      :fssID="selectedFssID"
      title="Matched Programs Available to Enroll"
      @close-modal="showFSSDetailsModal = false"
      @save-from-modal="handleConfirmFSSAddModal"
    />

    <ConfirmFSSRemovalModal
      v-if="selectedOrderID && showRemoveFSSModal"
      :selectedOrderName="selectedOrderName"
      :selectedOrderID="selectedOrderID"
      :fssID="selectedFssID"
      @close-modal="handleCloseFSSRemoveModal"
      @save-from-modal="handleConfirmFSSRemoveModal"
    />

    <FieldDetailsCompletion
      v-if="showFieldDetailsCompletion"
      :fieldID="selectedFieldID"
      :year="yearInFocus"
      @close-modal="handleCloseFieldDetailsCompletion"
    />
  </div>
</template>

<script>
import { Filter } from "@/store/modules"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"
import { mapState, mapMutations } from "vuex"
import ReviewFSSDetails from "@/components/insets/modals/ReviewFSSDetails"
import ConfirmFSSRemovalModal from "@/components/insets/modals/ConfirmFSSRemovalModal"
import FieldDetailsCompletion from "@/components/insets/modals/FieldDetailsCompletionModal"
import VDataTablesMultiSelectHeader from "@/components/misc/VDataTablesMultiSelectHeader"

export default {
  name: "FieldCropProgramManagement",
  components: {
    ReviewFSSDetails,
    ConfirmFSSRemovalModal,
    FieldDetailsCompletion,
    VDataTablesMultiSelectHeader,
  },
  props: ["activeFields", "targetFSSIds"],
  data() {
    return {
      selectedFSSForAllocation: [],
      showLoading: false,
      filterFieldSupplySummary: [],
      fieldSupplySummaryDataFiltered: [],
      filters: {
        crop_name: [],
        status: [],
        ea_orders_name: [],
        harvest_type: [],
        //matched_program: [],
      },
      filterFSSIds: [],
      fssPrograms: [],
      lastFilter: null,
      items: [],
      includedSearch: "",
      selectedFssID: null,
      showFSSDetailsModal: false,
      showRemoveFSSModal: false,
      showFieldDetailsCompletion: false,
      selectedOrderID: null,
      selectedOrerName: null,
      selectedFieldID: null,
      fieldIDString: "",
      enrollmentHeaders: [
        { text: "FSS", value: "id", width: "3%" },
        { text: "ID", value: "fieldcrop.field_id", width: "3%" },
        { text: "Field", value: "fieldcrop.field_name", width: "12%" },
        { text: "Client", value: "client", width: "8%" },
        { text: "Ac", value: "fieldcrop.acreage", width: "5%" }, //acres not currently available in serializer
        { text: "Crop", value: "crop_name", width: "15%" },
        { text: "Type", value: "harvest_type", width: "10%" },
        { text: "Practices", value: "practices", width: "8%" },
        //{ text: "Est CO2e Net", value: "scope3_emission", width: "10%" },
        //{ text: "Evidence", value: "evidence" },
        { text: "Status", value: "status", width: "10%" },

        //{ text: "Practices", value: "practices" },
        { text: "Program", value: "ea_orders_name", width: "18%" },
        //{ text: "Matched Program", value: "matched_program", align: " d-none" },

        //{ text: "Matched Program", value: "ea_orders" },
      ],
    }
  },

  methods: {
    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
    }),
    clearAllFilters() {
      this.filterFSSIds = []
      this.selectedFSSForAllocation = []
      for (let filter in this.filters) {
        this.filters[filter] = []
      }
      this.fieldIDString = ""
      this.includedSearch = ""
      this.setSelectedFields(
        this.fieldBoundaries.map(x => x.properties.field.id)
      )
      //NOTE may have to set field selection here too...

      this.fieldSupplySummaryDataFiltered = this.filterFieldSupplySummary
      this.fieldIDSearch("")
    },
    filteredData(e) {
      this.fieldSupplySummaryDataFiltered = e
      this.lastFilter = e
      this.fieldIDSearch(this.fieldIDString)
    },
    async fetchFieldSupplySummary() {
      const payload = {
        year: this.yearInFocus,
        exclude_open: true,
      }
      this.showLoading = true
      await CarbonReadyAPI.getFieldSupplySummary(payload).then(resp => {
        let fieldSupplySummary = resp.data.results
        this.filterFieldSupplySummary = fieldSupplySummary.filter(
          e => e.status != "open"
        )

        this.fieldSupplySummaryDataFiltered = this.filterFieldSupplySummary
        this.$emit("refreshAllocation")
        this.showLoading = false
      })
    },

    fieldIDSearch(fieldIDString) {
      if (fieldIDString == "" || fieldIDString == null) {
        if (this.lastFilter != null) {
          this.fieldSupplySummaryDataFiltered = this.lastFilter
        } else {
          this.fieldSupplySummaryDataFiltered = fieldSupplySummaryData //this.filterFieldSupplySummary
        }
      } else {
        let fieldIDs = []
        let fieldIDsSplit = fieldIDString.split(",")

        fieldIDsSplit.forEach(e => {
          e.split(" ").forEach(f => {
            if (f != " ") {
              fieldIDs.push(f)
            }
          })
        })

        this.fieldSupplySummaryDataFiltered =
          this.fieldSupplySummaryDataFiltered.filter(e => {
            return fieldIDs.includes(String(e.fieldcrop.field_id))
          })
      }
    },

    handeShowFieldDetailsCompletionModal(fss) {
      this.selectedFieldID = fss.fieldcrop.field_id
      this.showFieldDetailsCompletion = true
    },
    handleCloseFieldDetailsCompletion() {
      this.selectedFieldID = null
      this.showFieldDetailsCompletion = false
    },

    handleFSSDetailsModal(fss) {
      if (Array.isArray(fss)) {
        this.fssPrograms = []

        let fss_ids = fss.map(e => e.id)

        CarbonReadyAPI.postFSSRecommendations({
          fss_ids: fss.map(e => e.id),
        }).then(response => {
          this.fssPrograms = response.data[1]

          this.selectedFssID = fss_ids

          this.showFSSDetailsModal = true
        })
      } else {
        this.fssPrograms = []

        CarbonReadyAPI.getFSSRecommendations({
          fss_id: fss.id,
        }).then(response => {
          this.fssPrograms = response.data[1]

          this.selectedFssID = fss.id

          this.showFSSDetailsModal = true
        })
      }
    },

    handleOpenFSSRemoveModal(e) {
      this.selectedFssID = e.id
      this.selectedOrderName = e.ea_orders.name
      this.selectedOrderID = e.ea_orders.id

      this.showRemoveFSSModal = true
    },
    handleConfirmFSSAddModal() {
      this.selectedFssID = null
      this.showFSSDetailsModal = false
      this.selectedFSSForAllocation = []

      this.fetchFieldSupplySummary()
      //this.$emit("refreshAllocation")
    },
    handleCloseFSSRemoveModal() {
      this.selectedFssID = null

      this.selectedOrderID = null
      this.selectedOrderName = null
      this.showRemoveFSSModal = false
    },
    handleConfirmFSSRemoveModal() {
      this.selectedFssID = null

      this.selectedOrderID = null
      this.selectedOrderName = null
      this.showRemoveFSSModal = false

      this.fetchFieldSupplySummary()
      //this.$emit("refreshAllocation")
    },
  },

  computed: {
    ...mapState({
      yearInFocus: state => state.Organization.year,
      fieldBoundaries: state => state.Map.fieldBoundaries,
    }),

    filteredSummaryText() {
      return (
        "Showing " +
        this.fieldSupplySummaryDataFiltered.length +
        " fieldcrops of " +
        this.filterFieldSupplySummary.length +
        " available"
      )
    },
    filterUsed() {
      return (
        this.fieldSupplySummaryDataFiltered.length !=
        this.filterFieldSupplySummary.length
      )
    },

    selectedAcreage() {
      let acreages = this.selectedFSSForAllocationFiltered.map(e => {
        return e.fieldcrop.acreage
      })

      let acreage_sum = acreages.reduce((partialSum, a) => partialSum + a, 0)
      return acreage_sum
    },

    selectedFSSForAllocationFiltered() {
      return this.selectedFSSForAllocation.filter(
        e =>
          [
            "available",
            "requested",
            "requested_pending",
            //"matched",
            //"confirmed",
          ].includes(e.status) && e.num_matches > 0
      )
    },
    fieldSupplySummaryData() {
      let activeFieldIds = this.activeFields.map(field => field.id)

      this.filterFieldSupplySummary.forEach(e => {
        if (Array.isArray(e.practices)) {
          let practice_list = "<ul>"
          e.practices.forEach(p => {
            practice_list += "<li>" + p.split(":")[1] + "</li>"
          })
          practice_list += "</ul>"
          e.practices = practice_list
        }

        e.fieldcrop.acreage = Math.round(e.fieldcrop.acreage)
        e.crop_name = e.fieldcrop.crop_name
        e.ea_orders_name = null
        if (e.ea_orders != null) {
          e.ea_orders_name = e.ea_orders.name //+ " (" + e.ea_orders.program_rollup.name + ")"
        }
      })
      var temporaryFilterFieldSupplySummary = this.filterFieldSupplySummary

      if (this.filterFSSIds.length > 0) {
        temporaryFilterFieldSupplySummary =
          this.filterFieldSupplySummary.filter(e => {
            return _.includes(this.filterFSSIds, e.id)
          })
      }
      if (activeFieldIds.length >= 0) {
        return temporaryFilterFieldSupplySummary.filter(e => {
          return _.includes(activeFieldIds, e.fieldcrop.field_id)
        })
      } else {
        return temporaryFilterFieldSupplySummary
      }
    },
  },

  watch: {
    yearInFocus() {
      this.fetchFieldSupplySummary()
      this.filterFSSIds = []
      for (let filter in this.filters) {
        this.filters[filter] = []
      }
      this.includedSearch = ""
      this.fieldIDString = ""
    },
    targetFSSIds() {
      this.filterFSSIds = this.targetFSSIds
    },
  },

  async mounted() {
    await this.fetchFieldSupplySummary()
    //this.fieldSupplySummaryDataFiltered = this.fieldSupplySummaryData
  },
}
</script>

<style scoped>
.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}
.v-data-table {
  overflow-x: auto;
  overflow-y: auto;
  max-height: "calc(90vh - 520px)";
}
</style>
